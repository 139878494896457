<template>
  <v-card class="pb-10">
    <v-container class="pa-6 mx-0">
      <v-row
        class="align-center mb-0"
      >
        <v-col
          cols="12"
        >
          <h2>
            Jam Pelajaran
          </h2>
          <small style="white-space: normal;">
            Halaman untuk menambahkan jam pelajaran
          </small>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col
        v-for="(hours, day) in lessonHours"
        :key="day"
        xl="4"
        lg="4"
        md="4"
        sm="12"
        cols="12"
      >
        <v-card
          class="ma-0
          ma-sm-0
          mr-md-1
          mr-lg-1
          mr-xl-1"
          height="100%"
        >
          <v-card-title>
            <span class="text-h6 font-weight-medium">{{ day.toUpperCase() }}</span>
          </v-card-title>
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th class="text-uppercase">
                    Jadwal
                  </th>
                  <th class="text-uppercase">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="hour in hours"
                  :key="hour.uuid"
                >
                  <td>{{ hour.start_at.slice(0,5) }} - {{ hour.end_at.slice(0,5) }}</td>
                  <td>
                    <div>
                      <v-img
                        width="20"
                        height="20"
                        src="../../assets/icons/trash.svg"
                        class="cursor-pointer p-0"
                        @click="deleteHour(hour.uuid)"
                      >
                      </v-img>
                    </div>
                  </td>
                </tr>
                <tr v-if="showAdd[day] == true">
                  <td>
                    <v-menu
                      v-model="set_start_hour[day]"
                      :close-on-content-click="false"
                      nudge-right="0"
                      nudge-top="20"
                      max-width="100%"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="lessonHour[day].start_at"
                          label="Jam Mulai"
                          readonly
                          class="my-5"
                          :prepend-icon="icons.mdiClockOutline"
                          hide-details
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="lessonHour[day].start_at"
                        format="24hr"
                        color="primary"
                        @input="set_start_hour[day] = false"
                      ></v-time-picker>
                    </v-menu>
                  </td>
                  <td>
                    <v-menu
                      v-model="set_end_hour[day]"
                      :close-on-content-click="false"
                      nudge-right="0"
                      nudge-top="20"
                      max-width="100%"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="lessonHour[day].end_at"
                          label="Jam Selesai"
                          readonly
                          class="my-5"
                          :prepend-icon="icons.mdiClockOutline"
                          hide-details
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="lessonHour[day].end_at"
                        format="24hr"
                        color="primary"
                        @input="set_end_hour[day] = false"
                      ></v-time-picker>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
          <div
            v-if="showAdd[day] == false"
            class="pa-4 text-center"
          >
            <v-btn
              color="primary"
              outlined
              @click="addLessonHour(day)"
            >
              Tambah Jam
            </v-btn>
          </div>
          <div
            v-if="showAdd[day] == true"
            class="pa-4 text-center"
          >
            <v-btn
              color="primary"
              class="mr-1"
              width="100"
              @click="add(day)"
            >
              Simpan
            </v-btn>
            <v-btn
              color="primary"
              outlined
              class="ml-1"
              width="100"
              @click="cancelAdd(day)"
            >
              Batal
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mdiClockOutline, mdiMagnify } from '@mdi/js'

export default {
  name: 'LessonSchedule',
  components: {},
  data() {
    return {
      skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      isLoadingCard: true,
      deadline: false,
      icons: {
        mdiMagnify,
        mdiClockOutline,
      },
      showAdd: {},
      lessonHours: [],
      lessonHour: {
        senin: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'senin',
          start_at: '',
          end_at: '',
        },
        selasa: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'selasa',
          start_at: '',
          end_at: '',
        },
        rabu: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'rabu',
          start_at: '',
          end_at: '',
        },
        kamis: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'kamis',
          start_at: '',
          end_at: '',
        },
        jumat: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'jumat',
          start_at: '',
          end_at: '',
        },
        sabtu: {
          uuid: '',
          school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
          day: 'sabtu',
          start_at: '',
          end_at: '',
        },
      },
      school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
      set_start_hour: {
        senin: false,
        selasa: false,
        rabu: false,
        kamis: false,
        jumat: false,
        sabtu: false,
      },
      set_end_hour: {
        senin: false,
        selasa: false,
        rabu: false,
        kamis: false,
        jumat: false,
        sabtu: false,
      },
    }
  },
  mounted() {
    this.getHours()
  },
  methods: {
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    async getHours() {
      await this.$services.ApiServices.get('hours', this.school_uuid).then(({ data }) => {
        this.lessonHours = data.data
        Object.keys(this.lessonHours).forEach(day => {
          this.$set(this.showAdd, day, false)
        })
      })
    },
    async add(day) {
      await this.$services.ApiServices.add('hours', this.lessonHour[day]).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.getHours()
          this.lessonHour[day].start_at = ''
          this.lessonHour[day].end_at = ''
        },
        err => {
          console.error(err)
        },
      )
    },
    addLessonHour(day) {
      this.$set(this.showAdd, day, true)
    },
    async deleteHour(uuid) {
      await this.$services.ApiServices.destroy('hours', uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'error',
        })
        this.getHours()
      })
    },
    cancelAdd(day) {
      this.$set(this.showAdd, day, false)
      this.lessonHour[day].start_at = ''
    },
  },
}
</script>

<style>
</style>

<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="subjects"
          :search="search"
          title="Mata Pelajaran"
          subtitle="Halaman untuk menambahkan mata pelajaran"
          add-btn="Tambah Data"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          @delete-item="confirmDestroy"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Mata Pelajaran': 'Edit Mata Pelajaran'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="subject.code"
                label="Kode Pelajaran"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="subject.name"
                label="Mata Pelajaran"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="subject.class_uuid"
                :items="classes"
                item-text="name"
                item-value="uuid"
                label="Kelas"
                dense
                clearable
                outlined
              ></v-autocomplete>
            </div>
            <div>
              <v-autocomplete
                v-model="subject.teacher_uuid"
                :items="teachers"
                item-text="employee.name"
                item-value="uuid"
                label="Guru Bidang Studi/Pengajar"
                dense
                clearable
                outlined
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <div class="d-flex align-center">
                Status: <div class="ml-4">
                  <v-switch
                    v-model="subject.is_active"
                  ></v-switch>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Mata Pelajaran"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
      <template v-slot:body>
        <p>
          <b>Data yang dihapus termasuk :</b>
        </p>
        <p>
          - Data Mata Pelajaran
          <br>
          - Data Bab Pelajaran
          <br>
          - Data Latihan/Ujian/Materi
          <br>
          - Data Absensi Mata Pelajaran
        </p>
        <p>Seluruh data dari Mata Pelajaran ini akan dihapus <b>PERMANENT</b>. Silahkan ketik <b>"Delete Subject"</b> untuk validasi penghapusan Mata Pelajaran.</p>

        <v-text-field
          v-model="validateDelete"
          label="'Delete Subject'"
          dense
          type="text"
          outlined
        ></v-text-field>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  name: 'Subject',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    MainCard,
  },
  setup(props, context) {
    const attrs = ref({
      class: 'mb-6',
      boilerplate: false,
      elevation: 5,
      types: {},
    })
    const validateDelete = ref(null)
    const page = ref(1)
    const formValid = ref(false)
    const search = ref('')
    const dialog = ref(null)
    const width = ref(600)
    const totalPages = ref(0)
    const totalItems = ref(0)
    const modalDialog = ref(false)
    const confirmDialog = ref(false)
    const isLoadingTable = ref(true)
    const isLoadingData = ref(false)
    const isLoadingButton = ref(false)
    const school = ref([])
    const schoolUuid = ref('')
    const classes = ref([])
    const teachers = ref([])
    const filterQuery = ref({
      subject: '',
    })
    const teacher = ref({
      name: '',
      teacher_uuid: '',
      employee: {
        name: '',
      },
    })
    const subjects = ref([])
    const subject = ref({
      uuid: '',
      code: '',
      name: '',
      class_uuid: '',
      teacher_uuid: '',
      school_uuid: '',
      description: '',
      is_active: '',
    })

    const headers = ref([
      { text: 'No', value: 'index' },
      { text: 'Kode Pelajaran', value: 'code' },
      { text: 'Nama Pelajaran', value: 'name' },
      { text: 'Guru Bidang/Studi/Pengajar', value: 'teacher.employee.name' },
      { text: 'Kelas', value: 'class.name' },
      { text: 'Status', value: 'is_active' },
      { text: 'Aksi', value: 'actions' },
    ])
    const listClass = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('class', {
        school_uuid: subject.value.school_uuid,
        per_page: 'all',
      }).then(
        ({ data }) => {
          classes.value = data.data
        },
        err => {
          console.error(err)
        },
      )
      isLoadingData.value = false
    }
    const listTeacher = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('teacher', {
        school_uuid: subject.value.school_uuid,
        per_page: 'all',
      }).then(
        ({ data }) => {
          teachers.value = data.data
        },
        err => {
          console.error(err)
        },
      )
      isLoadingData.value = false
    }
    const paginationHandler = pageNumber => {
      page.value = pageNumber
    }
    const listSubject = async (params = { ...filterQuery.value }) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('subject', {
        ...params,
        page: page.value,
        school_uuid: schoolUuid.value,
      }).then(
        ({ data }) => {
          subjects.value = data.data.map((subjects, index) => ({
            ...subjects,
            index: index + 1,
          }))
          totalItems.value = data.meta.total
          totalPages.value = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      isLoadingTable.value = false
      isLoadingData.value = false
    }

    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const getSchoolUuid = () => {
      school.value = JSON.parse(localStorage.getItem('user')).user.details
      school.value.forEach(el => {
        if (el.school === undefined) {
          subject.value.school_uuid = el.uuid
          schoolUuid.value = el.uuid

          return
        }
        if (el.school === null) {
          showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        schoolUuid.value = el.uuid
        subject.value.school_uuid = el.school.uuid
      })

      return true
    }

    const resetForm = () => {
      subject.value.code = ''
      subject.value.name = ''
      subject.value.teacher_uuid = ''
      subject.value.class_uuid = ''
      subject.value.description = ''
      subject.value.is_active = ''
    }

    const showFormAdd = () => {
      dialog.value = 'add'
      modalDialog.value = true
      resetForm()
    }

    const add = async () => {
      formValid.value = true
      subject.value.is_active = subject.value.is_active ? '1' : '0'
      isLoadingButton.value = true
      await context.root.$services.ApiServices.add('subject', subject.value).then(
        res => {
          showSnackbar({
            text: 'Add Subject Has Successfully',
            color: 'success',
          })
          modalDialog.value = false
        },
        err => {
          isLoadingButton.value = false
          console.error(err)
        },
      )
      await listSubject()
      resetForm()
      isLoadingButton.value = false
    }

    const showFormEdit = async uuid => {
      dialog.value = 'edit'
      modalDialog.value = true
      await context.root.$services.ApiServices.get('subject', uuid).then(
        ({ data }) => {
          subject.value.uuid = data.data.uuid
          subject.value.code = data.data.code
          subject.value.name = data.data.name
          subject.value.teacher_uuid = data.data.teacher.uuid
          subject.value.class_uuid = data.data.class.uuid
        },
        err => console.error(err),
      )
    }

    const update = async () => {
      isLoadingButton.value = true
      subject.value.is_active = subject.value.is_active ? '1' : '0'
      await context.root.$services.ApiServices.update('subject', subject.value, subject.value.uuid).then(
        res => {
          showSnackbar({
            text: 'Update Subject Has Successfully',
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await listSubject()
      resetForm()
      modalDialog.value = false
      isLoadingButton.value = false
    }

    const confirmDestroy = uuid => {
      validateDelete.value = null
      confirmDialog.value = true
      subject.value.uuid = uuid
    }
    const destroy = async () => {
      if (validateDelete.value === null || validateDelete.value !== 'Delete Subject') {
        showSnackbar({
          text: "Please, type 'Delete Subject' first !!! ",
          color: 'error',
        })

        return
      }
      isLoadingButton.value = true
      await context.root.$services.ApiServices.destroy('subject', subject.value.uuid).then(
        res => {
          listSubject()
          showSnackbar({
            text: 'Delete Subject Has Successfully',
            color: 'error',
          })
        },
        err => console.error(err),
      )

      isLoadingButton.value = false
      confirmDialog.value = false
    }
    const handlerButton = () => {
      if (dialog.value === 'add') add()
      else update()
    }
    const searchHandler = subject => {
      page.value = 1
      filterQuery.value = {
        subject,
      }
      listSubject()
    }

    watch(page, () => {
      listSubject(filterQuery.value)
    })
    watch(subject.value, () => {
      const valid = []
      const requiredField = ['code', 'name', 'class_uuid', 'teacher_uuid']
      Object.entries(subject.value).forEach(([key, item]) => {
        if (requiredField.includes(key)) valid.push(!!(item && item.length))
      })
      formValid.value = !valid.includes(false)
    })

    onMounted(() => {
      getSchoolUuid()
      isLoadingTable.value = true
      listSubject()
      listTeacher()
      listClass()
    })

    return {
      formValid,
      validateDelete,
      attrs,
      add,
      classes,
      update,
      destroy,
      dialog,
      headers,
      teachers,
      teacher,
      subjects,
      subject,
      listSubject,
      listTeacher,
      getSchoolUuid,
      page,
      search,
      width,
      confirmDialog,
      confirmDestroy,
      isLoadingTable,
      isLoadingData,
      isLoadingButton,
      modalDialog,
      totalPages,
      totalItems,
      showFormAdd,
      showFormEdit,
      showSnackbar,
      searchHandler,
      paginationHandler,
      handlerButton,
      resetForm,
      school,
      schoolUuid,
      filterQuery,
    }
  },
}
</script>

<style>
</style>
